import { initMmenu, initImageGallery } from './init.helpers'
import { setupHistoryNav, setupForms, setupScrollListeners } from './setup.helpers'

const siteInit = () => {
	//Init libs
	document.addEventListener('DOMContentLoaded', () => {
		// Mobile nav
		initMmenu()

		// Image gallery
		initImageGallery()

		//Fixed nav on scroll
		setupScrollListeners()

		//History nav
		setupHistoryNav()

		//Forms
		setupForms()
	})
}
siteInit()
