export const setupHistoryNav = () => {
	try {
		//console.log('Wiring up history nav...')

		const $historyNavToggle = $('.history-nav-toggle')
		const $historyNav = $('.history-nav')
		const $historyNavOverlay = $('.overlay')

		const toggleHistoryNav = () => {
			if ($historyNav.hasClass('closed')) {
				$historyNav.removeClass('closed')
				$historyNav.addClass('open')
				$historyNavOverlay.addClass('show-overlay')
			} else {
				$historyNav.removeClass('open')
				$historyNav.addClass('closed')
				$historyNavOverlay.removeClass('show-overlay')
			}
		}
		$historyNavToggle.on('click', toggleHistoryNav)
	} catch (err) {
		console.error('History nav setup error: ', err)
	}
}

export const setupForms = () => {
	try {
		//console.log('Wiring up vendor form...')

		//vendor form
		const $foodVendorInfo = $('.food')
		const $foodBoothTypeSelect = $<HTMLSelectElement>('#booth-type')

		const showHideFood = () => {
			const value = $foodBoothTypeSelect.val()
			if (value !== 'Food') {
				$foodVendorInfo.removeClass('show')
			} else {
				$foodVendorInfo.addClass('show')
			}
		}

		$foodBoothTypeSelect.on('change', showHideFood)
	} catch (err) {
		console.error('Form setup error: ', err)
	}
}

export const setupScrollListeners = () => {
	try {
		//console.log('Wiring up scroll listeners...')

		const deskNav: any = $('.desktop-nav')
		deskNav.scrollToFixed()
	} catch (err) {
		console.error('Scrolling init error: ', err)
	}
}
