import baguetteBox from 'baguettebox.js'

export const initMmenu = () => {
	try {
		//console.log('Init menu...')
		new Mmenu(
			'#mobile-nav',
			{
				extensions: ['theme-dark'],
				navbar: {
					title: 'Quilcene Fair & Parade',
				},
			},
			{
				offCanvas: {
					page: {
						selector: '#wrap',
					},
				},
			}
		)
	} catch (err) {
		console.error('Mmenu init error: ', err)
	}
}

export const initImageGallery = () => {
	try {
		//console.log('Init gallery...')

		baguetteBox.run('.gallery', {
			animation: 'fadeIn',
			noScrollbars: true,
		})

		// Remove gallery caption if empty
		$('li.slide > p').each(function (_, cap) {
			if (cap.innerHTML === '') {
				cap.remove()
			}
		})
	} catch (err) {
		console.error('baguetteBox init error: ', err)
	}
}
